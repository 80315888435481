body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

span {
    display: inline-block;
    width: 15%;
    float: right;
}

a{
  color: #000;
}

a:hover {
   color: #219837 
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.homeimage{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: auto;
}

.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}
 
.iframe-container iframe {
   border: 0;
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
}

.footer {
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  color: white;
  z-index: 1;
}

.footer-links ul {
  margin: 0;
  padding: 0;
  display: flex;
}

.footer-links li {
  list-style: none;
}

.footer-links li a {
  text-decoration: none;
  color: white;
  padding: 1rem;
  display: block;
}

.footer-links li:hover {
  background-color: #b2b2b2;
}